import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import webSee from 'gsi-web-see';
import {supabase} from "@/utils/supabase.ts";
import {userSession} from "@/utils/useAuth.ts";

async function handleSignup(credentials) {
  try {
    const { email, password } = credentials;
    // prompt user if they have not filled populated their credentials
    if (!email || !password) {
      console.error(`web-see 缺少必须配置项：Please provide both your email and password.`);
      return;
    }
    const { error } = await supabase.auth.signIn({ email, password });
    if (error) {
      console.error(error, error.message);
      return;
    }
    console.log('SignIn successful');
  } catch (err) {
    alert('Fatal error signing up');
    console.error('signup error', err);
  }
}



const options = {
  dsn: 'https://web-see-server.lintao.fun/reportData',
  apikey: '项目1',
  silentRecordScreen: true,
  userId: '123',
  checkoutEveryNms:5*60,
  email:'496568527@qq.com',
  password:'ss2306171'
}

handleSignup(options)
Vue.use(webSee, options);

supabase.auth.onAuthStateChange((_event, session) => {
  userSession.session = session;
});


Vue.use(ElementUI, { size: 'mini' });
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
